import * as React from 'react';
import Button from './button';
import AnimatedLink from './animatedLink';
import CrossIcon from './icons/cross';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import Image from './image';
import Link from './link';

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const headerRef = React.useRef();
  const mobileRef = React.useRef();
  const tl = React.useRef();

  const {contextSafe} = useGSAP(
    () => {
      tl.current = gsap.timeline({
        paused: true
      })
        .from(mobileRef.current, {
          opacity: 0,
          y: '100%',
          duration: 0.3,
          onStart: () => {
            document.querySelector('html').classList.add('clip') 
          },
          onReverseComplete: () => {
            document.querySelector('html').classList.remove('clip')
          }
        })
        .from('.mobile-link', {
          opacity: 0,
          stagger: 0.2
        })
    },
    { scope: headerRef}
  )

  const handleToggle = contextSafe(() => {
    setMobileOpen(open => !open);
    (!mobileOpen && tl.current) ? tl.current.play(0) : tl.current.reverse();
  }, [mobileOpen])


  const headerSettings = useStaticQuery(graphql`
    query {
      sanityHeader {
        asideLogo {
          src: asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 250)
            publicURL: url
          }
          alt
          maxWidth
        }
        barLogo {
          src: asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 250)
            publicURL: url
          }
          alt
          maxWidth
        }
        asideMenuOne {
          links {
            link {
              label
              url
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
            childLinks {
              label
              url
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
          }
        }
        asideMenuTwo {
          links {
            link {
              label
              url
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
            childLinks {
              label
              url
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
          }
        }
        barCta {
          link {
            label
            attributes
            uploadFile {
                src: asset {
                  url
                }
                title
              }
            url
          }
          buttonColor
        }
        barMenu {
          links {
            link {
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
              label
              url
            }
            childLinks {
              label
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
              url
            }
          }
        }
      }
    } 
  `)

  const {
    asideLogo,
    barLogo,
    asideMenuOne,
    asideMenuTwo,
    barCta,
    barMenu
  } = headerSettings.sanityHeader;

  return (
    <header className='color-scheme-primary bg-[var(--color-scheme-background)] z-40'
      ref={headerRef}>
      <div className='container-padding flex flex-row flex-nowrap items-center py-8 relative'>
        <nav id="nav-links"
          aria-label="Nav menu"
          className='flex-row flex-wrap flex-1 md:gap-x-4 xl:gap-x-8 hidden md:flex'>
          {barMenu.links.map((i,k) =>
            <AnimatedLink to={i.link.url}
              key={`nav-links-${k}`}
              partiallyActive={true}
              attributes={i.link.attributes}
              className="text-[var(--color-scheme-foreground)] text-lg lg:text-xl font-light flex items-center">
              {i.link.label}
            </AnimatedLink>
          )}
        </nav>
        <div className='flex justify-center flex-1 md:flex-auto'>
          <Link href="/">
            {barLogo && <div><Image {...barLogo} /></div>}
          </Link>
        </div>
        <div className='flex-1 text-right hidden md:block'>
          <Button buttonColor={barCta.buttonColor}
            shouldAnimate={true}
            {...barCta.link} />
        </div>
        <div className='w-8 space-y-1.5 md:hidden cursor-pointer' 
          onClick={handleToggle}
          onKeyDown={handleToggle}
          role="button" 
          aria-label="Toggle menu"
          tabIndex={0}>
          <div className='w-full h-[2px] bg-[var(--color-scheme-foreground)] pointer-events-none'></div>
          <div className='w-1/2 h-[2px] bg-[var(--color-scheme-foreground)] pointer-events-none'></div>
        </div>
      </div>
      <div className={clsx('fixed w-screen h-screen left-0 top-0 color-scheme-secondary bg-[var(--color-scheme-background)] z-50', !mobileOpen && 'pointer-events-none')}
        ref={mobileRef}>
        <div className='container-padding py-12 flex flex-col gap-y-8 items-center h-full overflow-auto'>
          <div className='flex justify-end w-full'>
            <div onClick={handleToggle}
              onKeyDown={handleToggle}
              role="button" 
              aria-label="Toggle menu"
              tabIndex={0}>
              <CrossIcon className='mobile-link w-8 h-8 fill-[var(--color-scheme-foreground)] cursor-pointer' />
            </div>
          </div>
          {asideLogo && <div><Image {...asideLogo} className='mobile-link object-contain' objectFit='contain' /></div>}
          <nav id="nav-links"
            aria-label="Nav menu"
            className='flex flex-col gap-y-3 text-center'>
            {asideMenuOne.links.map((i,k) =>
              <AnimatedLink to={i.link.url}
                key={`mobile-nav-links-${k}`}
                attributes={i.link.attributes}
                onClick={handleToggle}
                className="mobile-link text-[var(--color-scheme-foreground)] text-2xl font-normal">
                {i.link.label}
              </AnimatedLink>
            )}
          </nav>
          <nav id="nav-links"
            aria-label="Nav menu"
            className='flex flex-col gap-y-3 text-center'>
            {asideMenuTwo.links.map((i,k) =>
              <AnimatedLink to={i.link.url}
                key={`mobile-contact-links-${k}`}
                attributes={i.link.attributes}
                onClick={handleToggle}
                className="mobile-link text-[var(--color-scheme-foreground)] text-2xl font-normal">
                {i.link.label}
              </AnimatedLink>
            )}
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Navbar;