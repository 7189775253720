import * as React from 'react';
import Heading from './heading';
import AnimatedLink from './animatedLink';
import Button from './button';
import { graphql, useStaticQuery } from 'gatsby';
import Image from './image';
import clsx from 'clsx';

const Footer = ({
  footerGroup
}) => {
  const footerSettings = useStaticQuery(graphql`
    query {
      sanityFooter {
        footerLogoOne {
          src: asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 250)
            publicURL: url
          }
          alt
          maxWidth
        }
        footerLogoTwo {
          src: asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 250)
            publicURL: url
          }
          alt
          maxWidth
        }
        menuHeading
        menuOne {
          id
          links {
            link {
              url
              label
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
          }
        }
        menuSocials {
          id
          links {
            link {
              attributes
              label
              url
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
          }
        }
        menuTwo {
          links {
            link {
              label
              url
              attributes
              uploadFile {
                src: asset {
                  url
                }
                title
              }
            }
          }
          id
        }
      }
      sanitySettings {
        entity
      }
    }

    fragment heroBanner on SanityHeroBanner {
      _key
      _type
      cta {
        buttonColor
        link {
          attributes
          label
          url
          uploadFile {
            src: asset {
              url
            }
            title
          }
        }
      }
      heading {
        value
        style {
          colorAccent
          headingElement
          headingType
        }
      }
      stretchAnimation
      staticText
      colorScheme
      anchor {
        current
      }
    }

    fragment logoCloud on SanityLogoCloud {
      _key
      _type
      colorScheme
      secondaryColorScheme
      anchor {
        current
      }
      heading {
        value
        style {
          colorAccent
          headingElement
          headingType
        }
      }
      logos {
        src: asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 250)
          publicURL: url
        }
        alt
        maxWidth
      }
    }

    fragment serviceCards on SanityServiceCards {
      _key
      _type
      colorScheme
      anchor {
        current
      }
      cardHeadingStyle {
        colorAccent
        headingElement
        headingType
      }
      cardSharedHeading
      elements
      heading {
        value
        style {
          colorAccent
          headingElement
          headingType
        }
      }
      speed {
        desktop
        mobile
        tablet
      }
      cards {
        icon
        title
        text: _rawText(resolveReferences: {maxDepth: 10})
      }  
    }

    fragment projectShowcase on SanityProjectShowcase {
      _key
      _type
      colorScheme
      staticLabel
      anchor {
        current
      }
      cards {
        colorScheme
        title
        link {
          url
          label
          attributes
          uploadFile {
            src: asset {
              url
            }
            title
          }
        }
        image {
          src: asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            publicURL: url
          }
          alt
          maxWidth
        }
      }
    }

    fragment cascadingBanner on SanityCascadingBanner {
      _key
      _type
      lines {
        iconCount
        iconPosition
        text {
          colorAccent
          style
          text
        }
      }
      cta {
        buttonColor
        link {
          attributes
          label
          url
          uploadFile {
            src: asset {
              url
            }
            title
          }
        }
      }
      colorScheme
      anchor {
        current
      }
    }

    fragment horizontalMenuBanner on SanityHorizontalMenuBanner {
      _key
      _type
      anchor {
        current
      }
      colorScheme
      title: heading
      linkOne {
        label
        url
        attributes
        uploadFile {
          src: asset {
            url
          }
          title
        }
      }
      linkThree {
        url
        label
        attributes
        uploadFile {
          src: asset {
            url
          }
          title
        }
      }
      linkTwo {
        url
        label
        attributes
        uploadFile {
          src: asset {
            url
          }
          title
        }
      }
    }

    fragment textbanner on SanityTextBanner {
      _key
      _type
      colorScheme
      shouldAnimate
      text
      anchor {
        current
      }
    }

    fragment formBanner on SanityFormBanner {
      _key
      _type
      anchor {
        current
      }
      colorScheme
      description: _rawText(resolveReferences: {maxDepth: 10})
      heading {
        style {
          colorAccent
          headingElement
          headingType
        }
        value
      }
      form {
        title
        terms: _rawTerms(resolveReferences: {maxDepth: 10})
        submissionPosition
        submissionLabel
        submissionButtonColor
        gridColumns
        confirmationRequired
        formId {
          current
        }
        fields: _rawFields(resolveReferences: {maxDepth: 10})
      }
    }

    fragment pageBanner on SanityPageBanner {
      _key
      _type
      description: _rawText(resolveReferences: {maxDepth: 10})
      heading {
        value
        style {
          colorAccent
          headingElement
          headingType
        }
      }
      cta {
        buttonColor
        link {
          attributes
          label
          url
          uploadFile {
            src: asset {
              url
            }
            title
          }
        }
      }
      colorScheme
      anchor {
        current
      }
    }

    fragment pricingBanner on SanityPricingBanner {
      _key
      _type
      heading {
        value
        style {
          headingType
          headingElement
          colorAccent
        }
      }
      text: description
      cta {
        buttonColor
        link {
          url
          label
          attributes
          uploadFile {
            src: asset {
              url
            }
            title
          }
        }
      }
      colorScheme
      anchor {
        current
      }
      cardHeadingStyle {
        headingElement
        headingType
        colorAccent
      }
      cards {
        title
        price
        caption
      }
    }

    fragment pageText on SanityPageText {
      _key
      _type
      colorScheme
      anchor {
        current
      }
      heading {
        value
        style {
          headingType
          headingElement
          colorAccent
        }
      }
      body: _rawText(resolveReferences: {maxDepth: 10})
    }
  `)

  const {
    footerLogoOne,
    footerLogoTwo,
    menuHeading,
    menuOne,
    menuTwo,
    menuSocials
  } = footerSettings?.sanityFooter || {};

  return (
    <footer className='color-scheme-primary bg-[var(--color-scheme-background)] py-24 border-t border-[color:var(--color-scheme-foreground)]'>
      <div className='container-padding space-y-12'>
        <div className='flex flex-row flex-nowrap gap-x-12 justify-center md:justify-between items-center'>
          {footerLogoOne && <div><Image {...footerLogoOne} /></div>}
          {footerLogoTwo && <div><Image {...footerLogoTwo} /></div>}
        </div>
        <div>
          {menuHeading && <Heading headingType="h5"
            value={menuHeading}
            className='mb-6 text-center md:text-left' />}
          <div className='flex flex-col md:flex-row flex-wrap gap-y-8 gap-x-16 text-center md:text-left items-center md:items-end'>
            <div className='flex flex-row gap-x-16 gap-y-8'>
              {(menuOne?.links ?? []).length > 0 && <nav 
                aria-label="Nav menu"
                className='flex flex-col gap-y-1'>
                {menuOne.links.map((i,k) => (
                  <AnimatedLink to={i.link.url}
                    key={`footer-menu-one-${k}`}
                    attributes={i.link.attributes}
                    className="text-[var(--color-scheme-foreground)] text-xl font-light">
                    {i.link.label}
                  </AnimatedLink>
                ))}
              </nav>}
              {(menuTwo?.links ?? []).length > 0 && <nav 
                aria-label="Nav menu"
                className='flex flex-col gap-y-1'>
                {menuTwo.links.map((i,k) => (
                  <AnimatedLink to={i.link.url}
                    key={`footer-menu-two-${k}`}
                    attributes={i.link.attributes}
                    className="text-[var(--color-scheme-foreground)] text-xl font-light">
                    {i.link.label}
                  </AnimatedLink>
                ))}
              </nav>}
            </div>
            <div className='flex-1 space-y-6 md:space-y-4'>
              {(menuSocials?.links ?? []).length > 0 && <div className='flex flex-col md:flex-row flex-wrap gap-x-3 gap-y-4 justify-end items-start md:items-center'>
                {menuSocials.links.map((i,k) => (
                  <div className='flex md:items-center gap-x-3 w-full md:w-auto'
                    key={k}>
                    <Button label={i.link.label}
                      url={i.link.url}
                      buttonColor="buttonTwo"
                      fontSize="text-lg"
                      className='w-full'
                      attributes={i.link.attributes}
                      shouldAnimate={true} />
                    <Button label="&rarr;"
                      buttonColor="buttonTwo"
                      fontSize="text-lg"
                      colorOverride={clsx(
                        (k === 0 || k > 2) && "bg-[var(--color-scheme-background)] text-[color:var(--color-scheme-foreground)] border-[color:var(--color-scheme-accent-one)]",
                        k === 1 && "bg-[var(--color-scheme-accent-one)] text-[color:var(--color-scheme-foreground)] border-[color:var(--color-scheme-accent-one)]",
                        k === 2 && "bg-[var(--color-scheme-background)] text-[color:var(--color-scheme-accent-one)] border-[color:var(--color-scheme-foreground)]"
                      )}
                      className="cursor-auto" />
                  </div>
                ))}
              </div>}
              
              <p className='text-[var(--color-scheme-foreground)] text-center md:text-right text-base md:text-lg'>&copy; {footerSettings.sanitySettings.entity}, {new Date().getFullYear()}. Don't steal our cheese.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;