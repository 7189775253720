import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

const VerticalStaticText = () => {
  const {
    sanitySettings: {
      staticText
    }
  } = useStaticQuery(graphql`
    query {
      sanitySettings {
        staticText
      }
    }  
  `)
  return (
    <div className='color-scheme-primary text-[color:var(--color-scheme-foreground)] w-0 h-0 fixed mt-10 right-2 z-50 hidden md:block pointer-events-none'>
      <div className='rotate-90 text-nowrap'>{staticText}</div>
    </div>
  )
}

export default VerticalStaticText;