import './src/styles/global.css'
import * as React from 'react';
import SanityPreviewConnectorProvider from './src/components/preview/sanityPreviewConnector';
import PreviewProvider from './src/providers/previewProvider';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/all';
import { ScrollSmoother } from 'gsap/all';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import SplitText from 'gsap/SplitText';
import ScrambleTextPlugin from 'gsap/ScrambleTextPlugin';

if(typeof window !== undefined) {
  gsap.registerPlugin(useGSAP, SplitText, MotionPathPlugin, DrawSVGPlugin, ScrollTrigger, ScrollSmoother, ScrollToPlugin, ScrambleTextPlugin);
}

if(typeof window !== undefined) {
  gsap.to(window, {duration: 1, scrollTo: 0})
}

export const wrapRootElement = ({element}) => {
  return (
    <PreviewProvider>
      <SanityPreviewConnectorProvider>
        {element}
      </SanityPreviewConnectorProvider>
    </PreviewProvider>
  )
}

export const shouldUpdateScroll = ({
  routerProps
}) => {
  return true;
}