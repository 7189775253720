import * as React from 'react';
import Navbar from './navbar';
import Footer from './footer';
import { useGSAP } from '@gsap/react';
import { ScrollSmoother } from 'gsap/all';
import { PreviewContext } from '../providers/previewProvider';
import PageLoadProvider from './pageLoadProvider';
import VerticalStaticText from './verticalStaticText';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Layout = ({
  location,
  children
}) => {
  const main = React.useRef();
  const smoother = React.useRef();
  const pathname = location ? location.pathname.replace(/^\//, '').replace(/\/$/, '') : '/';
  const {isPreview} = React.useContext(PreviewContext);
  useGSAP(
    () => {
      smoother.current = ScrollSmoother.create({
        smooth: 2,
        effects: true,
        normalizeScroll: true,
        ignoreMobileResize: true
      })
      if(location.hash.length) {
        gsap.to(window, {duration: 2, scrollTo: location.hash, delay: 0.6})
      }
    },
    { scope: main, dependencies: [location]}
  )

  React.useEffect(() => {
    // console.log(smoother.current)
  }, [location])

  React.useEffect(() => {
    const observer = new MutationObserver((records) => {
      const html = records[0];
      if(html.attributeName === 'class') {
        smoother.current.paused(document.documentElement.classList.contains('clip'))
      }
    })
    observer.observe(document.documentElement, {
      subtree: false,
      attributeOldValue: true,
    })
  }, [])

  const Content = () => {
    return(
      <div>
        <VerticalStaticText />
        <div id="smooth-wrapper"
          ref={main}>
          <div id="smooth-content"
            className='flex flex-col'>
            <Navbar pathname={pathname} />
            <main className='flex-1 relative'>{children}</main>
            <Footer />
          </div>
        </div>
      </div>
    )
  }

  if(isPreview) {
    return <Content />
  }
  
  return (
    <PageLoadProvider location={location}>
      <Content />
    </PageLoadProvider>
  )
}

export default Layout;