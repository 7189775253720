import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

const Image = ({
  src,
  alt,
  className = '',
  style = {},
  maxWidth,
  ...props
}) => {
  if(src === null || src === undefined) {
    return <></>
  }
  const isGatsbyImage = (src) => {
    return (src.childImageSharp !== null && src.childImageSharp !== undefined) || (src.gatsbyImage !== null && src.gatsbyImage !== undefined)  || (src.gatsbyImageData !== null && src.gatsbyImageData !== undefined);
  }
  const classes = clsx(className, maxWidth && 'max-w-[var(--image-max-width)]');
  const styles = {...style};
  if(maxWidth) {
    styles['--image-max-width'] = `${maxWidth}px`;
  }
  return (
    <>
    {isGatsbyImage(src)
      ? <GatsbyImage image={getImage(src)}
        alt={alt || 'Image'}
        className={classes}
        style={styles}
        {...props} />
      : <img src={src.publicURL}
          alt={alt}
          style={styles}
          className={classes} />}
    </>
  )
}

export default Image;