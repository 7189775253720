import clsx from 'clsx';
import * as React from 'react';
import Link from './link';
import { Button as HeadlessButton } from '@headlessui/react'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Button = ({
  label,
  url,
  buttonColor,
  className,
  children,
  uploadFile = {},
  rounded = 'rounded-full',
  border = 'border-2',
  fontWeight = "font-regular",
  fontSize = 'text-base',
  colorOverride = false,
  px = 'px-3',
  py = 'pt-1 pb-1.5',
  shouldAnimate,
  isLoading,
  type = 'button',
  ...props
}) => {
  const colorClasses = colorOverride ? colorOverride : buttonColor === 'buttonTwo'
    ? "bg-[var(--color-scheme-button-two-background)] text-[color:var(--color-scheme-button-two-foreground)] border-[color:var(--color-scheme-button-two-border)] hover:bg-[var(--color-scheme-button-two-background-active)] hover:text-[color:var(--color-scheme-button-two-foreground-active)] hover:border-[color:var(--color-scheme-button-two-border-active)]" 
    : "bg-[var(--color-scheme-button-one-background)] text-[color:var(--color-scheme-button-one-foreground)] border-[color:var(--color-scheme-button-one-border)] hover:bg-[var(--color-scheme-button-one-background-active)] hover:text-[color:var(--color-scheme-button-one-foreground-active)] hover:border-[color:var(--color-scheme-button-one-border-active)]"

  const buttonClassName = clsx("group/button w-auto inline-block text-center", rounded, border, fontWeight, fontSize, px, py, colorClasses, className, shouldAnimate)

  const buttonRef = React.useRef();
  
  const {contextSafe} = useGSAP({ scope: buttonRef });

  const onButtonHover = contextSafe(() => {
    gsap.fromTo('.label-one', {
      translateY: 0,
    }, { 
      translateY: '100%', 
      ease: 'bounce.out',
      duration: 0.7
    })
    gsap.fromTo('.label-two', {
      translateY: '-100%',
    }, { 
      translateY: 0, 
      ease: 'bounce.out',
      duration: 0.7
    });
  })

  const Label = () => {
    return shouldAnimate
      ? <div className='relative overflow-hidden'>
          <span className='inline-block label-one'>{label ? label : children}</span>
          <span className='inline-block absolute top-0 left-0 h-full w-full label-two'>{label ? label : children}</span>
        </div>
      : <span className='inline-block'>{label ? label : children}</span>
  }

  const hash = url && url[0] === '#';
  if(hash) {
    props.onClick = contextSafe(() => {
      gsap.to(window, {duration: 2, scrollTo: url, overwrite: 'auto'})
    })
  }
  
  if((url || uploadFile?.src) && !hash) {
    return <Link className={buttonClassName}
      ref={buttonRef}
      to={url ? url : `${uploadFile?.src?.url}`}
      onMouseEnter={shouldAnimate ? onButtonHover : () => {}}
      {...props}>
      <Label />
    </Link>
  }

  return <HeadlessButton as={React.Fragment} 
    onMouseEnter={shouldAnimate ? onButtonHover : () => {}}
    type={type}>
    {() => (
      <button className={buttonClassName}
      ref={buttonRef}
      {...props}>
        <Label />
      </button>
    )}
  </HeadlessButton>
}

export default Button;