import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import * as React from 'react';

const defaultContext = {
  isLoading: null
}

const PageLoadContext = React.createContext(defaultContext);

const PageLoadProvider = ({
  location,
  children
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const containerRef = React.useRef();
  const tl = React.useRef();

  useGSAP(
    () => {
      document.documentElement.classList.add('clip')
      tl.current = gsap.timeline()
        .set('svg', {visibility: 'visible'})
        .from('path', {
          duration: 1,
          drawSVG: 0,
          opacity: 0
        })
        .to(containerRef.current, {
          y: '-100%',
          duration: 0.2,
          onComplete: () => {
            document.documentElement.classList.remove('clip')
            setIsLoading(false)
          }
        })
        .set('svg', {visibility: 'hidden'})
    },
    {scope:containerRef}
  )

  // const jumpToHash = React.useCallback(() => {
  //   // console.log(location)
  //   !isLoading && location.hash && setTimeout(() => {
  //     // console.log('heeeeeere we go')
  //     gsap.to(window, {duration: 2, scrollTo: location.hash, overwrite: "auto"})
  //   }, 500)
  // }, [location, isLoading])
  
  // React.useEffect(() => {
  //   jumpToHash();
  // }, [location, jumpToHash])

  return (
    <PageLoadContext.Provider
      value={{
        isLoading
      }}>
      <div className='w-full h-dvh fixed min-h-dvh bg-[var(--color-scheme-background)] color-scheme-primary'
        style={{
          zIndex: '9999'
        }}
        ref={containerRef}>
          <div className='w-full h-full absolute top-0 left-0 flex items-center justify-center px-12'>
            <svg xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 274.55 184.16"
              className='stroke-[var(--color-scheme-accent-one)] fill-[var(--color-scheme-background)] w-full max-w-[400px] stroke-2'
              style={{
                visibility: 'hidden'
              }}>
                <path d="M270.47,74.54c-4.07-7.96-12.58-12.65-21.52-12.65h-70.99l-30.25,30.25h80.8c1.64,0,2.46,1.98,1.3,3.14l-51.8,51.8c-1.36,1.36-3.55,1.36-4.91,0l-40.17-40.17-21.39,21.39,47,47c4.28,4.28,9.95,7.09,16,7.34,6.6.28,12.85-2.16,17.49-6.8l74.19-74.19c7.13-7.13,8.91-17.99,4.25-27.11" />
                <path d="M4.08,109.62c4.07,7.96,12.58,12.65,21.52,12.65h70.99s30.25-30.25,30.25-30.25H46.04c-1.64,0-2.46-1.98-1.3-3.14l51.8-51.8c1.35-1.35,3.55-1.35,4.91,0l40.17,40.17,21.39-21.39L116,8.86c-4.28-4.28-9.95-7.08-16-7.34-6.6-.28-12.85,2.16-17.49,6.8L8.33,82.51c-7.13,7.13-8.91,17.99-4.25,27.11" />
            </svg>
          </div>
        </div>
      {children}
    </PageLoadContext.Provider>
  )
}

export default PageLoadProvider;
export {PageLoadContext};