import clsx from 'clsx';
import * as React from 'react';
import Link from './link';
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const Heading = ({
  headingType,
  headingElement = 'default',
  colorAccent,
  className,
  value,
  url
}) => {
  const colorClass = () => {
    switch (colorAccent) {
      case 'one':
        return 'text-[color:var(--color-scheme-accent-one)]';
      case 'two':
        return 'text-[color:var(--color-scheme-accent-two)]';
      default:
        return 'text-[color:var(--color-scheme-foreground)]';
    }
  }
  const headingStyle = () => {
    switch (headingType) {
      case 'h1':
        return 'xl:text-8xl md:text-7xl text-6xl';
      case 'h2':
        return 'md:text-6xl text-5xl';
      case 'h3':
        return 'md:text-5xl text-4xl';
      case 'h4':
        return 'md:text-4xl text-3xl';
      case 'h5':
        return 'md:text-3xl text-2xl';
      case 'h6':
        return 'md:text-2xl text-xl';
      default:
        return '';
    }
  }

  const HeadingTag = ({children, className, ...props}) => {
    const headingClasses = clsx('w-ful, font-semibold', headingStyle(), colorClass(), className);
    switch (headingElement) {
      case 'default':
        switch (headingType) {
          case 'h1':
            return <h1 className={headingClasses} {...props}>{children}</h1>
          case 'h2':
            return <h2 className={headingClasses} {...props}>{children}</h2>
          case 'h3':
            return <h3 className={headingClasses} {...props}>{children}</h3>
          case 'h4':
            return <h4 className={headingClasses} {...props}>{children}</h4>
          case 'h5':
            return <h5 className={headingClasses} {...props}>{children}</h5>
          case 'h6':
            return <h6 className={headingClasses} {...props}>{children}</h6>
          default:
            return <span className={headingClasses} {...props}>{children}</span>
        }
      case 'div':
        return <div className={headingClasses} {...props}>{children}</div>
      case 'p':
        return <p className={headingClasses} {...props}>{children}</p>
      default:
        return <span className={headingClasses} {...props}>{children}</span>
    }
    
  }

  const Heading = ({children}) => <HeadingTag className={className}>{children}</HeadingTag>;
  const HeadingMarkdown = () => <Markdown rehypePlugins={[rehypeRaw]}>{value}</Markdown>

  return (
    <Heading>
      {url ? <Link to={url}
        className="no-underline"
        target="_blank"
        rel="nofollow"><HeadingMarkdown /></Link>
        : <HeadingMarkdown />}
    </Heading>
  )
}

export default Heading;