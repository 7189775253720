import * as React from "react"
import Layout from "../components/layout"
import Heading from "../components/heading"
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import { useGSAP } from "@gsap/react"
import Button from "../components/button"

const NotFoundPage = ({
  location
}) => {
  const sectionRef = React.useRef();
  useGSAP(
    () => {
      gsap.set('.stage',  {perspective:800})
      gsap.set('.txt',    {left:'50%', top:'50%', xPercent:-50, yPercent:-50, whiteSpace:'nowrap'})

      let chars = new SplitText('.txt', {
        type: 'chars', 
      }).chars;

      gsap.timeline() 
        .set(chars, {
          x:0, 
          left:'50%',
          width: '0px',
          transformPerspective:800, 
          transformOrigin:'0 0 -300'
        })
        .fromTo(chars, {
          rotationY: (i)=>i/chars.length*360
        }, 
        { 
          rotationY:'-=360', 
          duration:16, 
          ease:'none', 
          repeat:-1
        }, 0)
        .fromTo(chars, {
          rotationX:-9
        }, {
          rotationX:12, 
          duration:6, 
          ease:'power3.inOut', 
          yoyo:true, 
          repeat:-1
        }, 0)
        .fromTo('.txt', {
          rotation:20
        }, {
          rotation:-10, 
          duration:10, 
          ease:'power2.inOut', 
          yoyo:true, 
          repeat:-1
        }, 0)

        for (let i=0; i<chars.length; i++){ 
          gsap.timeline({repeat:-1})
              .fromTo(chars[i], {zIndex:1000, color:'#fdfbdb'}, {zIndex:1, color:'#244353', duration:3, ease:'power1'}, 0)
              .to(chars[i],     {color:'#000', duration:4, ease:'expo'})
              .to(chars[i],     {color:'#ef4c23', duration:4, ease:'power3.in'})  
              .to(chars[i],     {color:'#fdfbdb', duration:2, ease:'none'})
              .to(chars[i],     {zIndex:1000, color:'#fdfbdb', duration:3, ease:'power3.inOut'})
              .progress(1-i/chars.length)
        }
    },
    {scope: sectionRef}
  )
  return (
    <Layout location={location}>
      <section className="color-scheme-primary py-24 bg-[var(--color-scheme-background)]"
        ref={sectionRef}>
        <div className="contain min-h-[600px] text-center">
          <Heading headingType="h1"
            value="404: Swiss You Were Here"
            className='text-center' />
          <div className="w-full flex items-center justify-center min-h-[400px]">
            <div class="txt text-[color:var(--color-scheme-foreground)] text-7xl font-black">404&#8226;404&#8226;404&#8226;404&#8226;404&#8226;404&#8226;</div>
          </div>
          <div className="flex items-center gap-x-2 justify-center">
            <Button label="&larr;"
              buttonColor="buttonOne"
              url="/"
              fontSize="text-base sm:text-lg" />
            <Button buttonColor="buttonTwo"
              fontSize="text-xl"
              shouldAnimate={true}
              url="/"
              label="Let's Brie-turn to Earth" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
