import * as React from 'react';
import Link from './link';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const AnimatedLink = ({
  children,
  ...props
}) => {
  const containerRef = React.useRef();
  const labelRef = React.useRef([]);
  
  const {contextSafe} = useGSAP({ scope: containerRef });
  const onLinkHover = contextSafe(() => {
    gsap.fromTo('.label-one', {
      translateY: 0,
    }, { 
      translateY: '100%', 
      ease: 'bounce.out',
      duration: 0.7
    });
    gsap.fromTo('.label-two', {
      translateY: '-100%',
    }, { 
      translateY: 0, 
      ease: 'bounce.out',
      duration: 0.7
    });
  })

  return (
    <Link {...props}
      onMouseEnter={onLinkHover}>
      <span ref={containerRef}
        className='inline-block relative overflow-hidden'>
        <span ref={el => labelRef.current[0] = el}
          className='inline-block label-one'>{children}</span>
        <span ref={el => labelRef.current[1] = el}
          className='inline-block absolute top-0 left-0 h-full w-full label-two'>{children}</span>
      </span>
    </Link>
  )
}

export default AnimatedLink;