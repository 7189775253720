import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { globalHistory } from '@reach/router'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Link = React.forwardRef((props, ref) => {
  const {
    children, 
    to, 
    activeClassName, 
    partiallyActive, 
    attributes = [], 
    ...other 
  } = props;
  const internal = /^\/(?!\/)/.test(to);
  let hash = false;

  const {contextSafe} = useGSAP({ scope: ref });
  if(internal && to.includes('#')) {
    const {
      location
    } = globalHistory
    const split = to.split('#');
    if(location.pathname.replace(/\/(?=[^\/]*$)/, '') === split[0].replace(/\/(?=[^\/]*$)/, '')) {
      other.onClick = contextSafe((e) => {
        e.preventDefault();
        gsap.to(window, {duration: 2, scrollTo: `#${split[1]}`, overwrite: 'auto'})
      })
      hash = true;
    }
  }
  
  if(internal && !hash) {
    return (
      <GatsbyLink to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        ref={ref}
        {...other}>
        {children}
      </GatsbyLink>
    )
  }
  const linkProps = other;
  const rel = [];
  attributes.forEach(i => {
    switch (i) {
      case 'newWindow':
        linkProps.target = "_blank";
        break;
      case 'nofollow':
      case 'sponsored':
      case 'ugc':
        rel.push(i);
        break;
      default:
        break;
    }
  })
  if(rel.length > 0) {
    linkProps.rel = rel.join(' ');
  }
  return (
    
    <a href={to} {...linkProps}
      ref={ref}>
      {children}
    </a>
  )
})

export default Link;